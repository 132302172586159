<template>
  <div>
    Farmaceutica
    <!-- <vue-editor v-model="content"></vue-editor> -->
    <editor
      v-model="content"
      @init="init_code('html')"
      lang="html"
      theme="monokai"
      style="height: 400px"
    />
    <a class="button is-success" @click="save" style="margin-op: 15px">
      <span class="icon is-small"> </span>
      <span>Guardar</span>
    </a>
    <!-- </section> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { api_host, post_request, error_toast } from "@/utils";
// import { VueEditor } from "vue2-editor";
import Editor from "vue2-ace-editor";

export default {
  name: "Pharmaceutical",
  components: { /*VueEditor,*/ Editor },
  filters: {},
  data() {
    return {
      api_host: api_host,
      content: "",
    };
  },
  computed: {
    token_session() {
      let token = this.$session.get("token");
      return token;
    },
    time() {
      let vm = this;
      return vm.$attrs && vm.$attrs.selected_time
        ? vm.$attrs.selected_time.value
        : "";
    },
  },
  methods: {
    init_code(type) {
      require(`brace/mode/${type}`);
      require("brace/theme/monokai");
    },
    save() {
      let vm = this;
      post_request(vm, "/admin/do-save-pharmaceutical", {
        content: vm.content,
        time: vm.time,
      })
        .then(() => {
          error_toast(vm, "Información almacenada");
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    update() {
      let vm = this;
      post_request(vm, "/admin/do-get-pharmaceutical", {
        time:
          vm.$attrs && vm.$attrs.selected_time
            ? vm.$attrs.selected_time.value
            : undefined,
      })
        .then((response) => {
          vm.content = response;
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
  },
  mounted() {},
  created() {
    this.update();
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
<style scoped>
.tr-green {
  background-color: lightgreen;
}

.tr-red {
  background-color: lightcoral;
}
</style>
