<template>
  <div>
    <h1 class="title">Subir fichero</h1>
    <!-- <section class="tab-content" v-show="current_tab == 'product'"> -->
    <file-pond
      name="file"
      ref="pond"
      label-idle="click or drop files here"
      v-bind:allow-multiple="true"
      v-bind:allow-revert="false"
      v-bind:server="{
        url: api_host,
        timeout: 7000,
        process: {
          url: '/admin/do-upload-priorities?time=' + time,
          method: 'POST',
          headers: {
            'x-access-token': token_session,
          },
          withCredentials: false,
        },
      }"
      v-on:error="filepond_error"
      v-on:processfile="filepond_process"
    />
    <table class="table" width="100%" style="margin-top: 15px">
      <thead>
        <tr>
          <th>Cadena</th>
          <th>Marca</th>
          <th>Prioridad</th>
          <th>Imágen</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in file_data"
          :key="index"
          v-bind:class="{
            'tr-green': row.success,
            'tr-red': !row.success,
          }"
        >
          <th>{{ row["Cadena"] }}</th>
          <th>{{ row["Marca"] }}</th>
          <th>{{ row["Prioridad"] }}</th>
          <th>{{ row["Ruta"] }}</th>
          <th>{{ row.success ? "OK" : row.error }}</th>
        </tr>
      </tbody>
    </table>

    <h1 class="title">Mantenimiento</h1>

    <!-- <pre> {{ this.$attrs }}</pre> -->

    <!-- <a class="button is-success" @click="upload_data">
      <span class="icon is-small">
        <i class="fas fa-check"></i>
      </span>
      <span>Upload data</span>
    </a> -->
    <table
      class="ui celled very compact selectable striped table"
      ref="table"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>{{ "business" }}</th>
          <th>{{ "brand" }}</th>
          <th>{{ "priority" }}</th>
          <th>{{ "image" }}</th>
          <!-- <th>{{ $t("options") }}</th> -->
        </tr>
      </thead>
      <tbody></tbody>
    </table>

    <!-- </section> -->

    <item-modal
      v-show="modal_visible"
      @close="modal_visible = false"
      @success="success"
      ref="item_modal"
    />
  </div>
</template>

<script>
import moment from "moment";
// @ is an alias to /src
import { api_host, error_toast, post_request, init_datable } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import ItemModal from "./ItemModal.vue";

const FilePond = vueFilePond();

export default {
  name: "Priorities",
  props: {
    selected_time: {
      type: Object,
      default: function () {
        return "";
      },
    },
  },
  components: {
    FilePond,
    ItemModal,
  },
  filters: {
    format_date(date) {
      if (!date) return "";
      else return moment(date).format("DD/MM/YYYY");
    },
    format_currency(value) {
      if (!value) return "";
      else {
        const formatter = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 2,
        });
        return formatter.format(value);
      }
    },
  },
  data() {
    return {
      api_host: api_host,
      file_data: [],
      current_tab: undefined,
      modal_visible: false,
    };
  },
  computed: {
    token_session() {
      let token = this.$session.get("token");
      return token;
    },
    time() {
      let vm = this;
      return vm.$props && vm.$props.selected_time
        ? vm.$props.selected_time.value
        : {};
    },
  },
  methods: {
    filepond_error(a, b) {
      console.log("error", a, b);
    },
    filepond_process(event, inst) {
      let serverResponse = inst.serverId;
      let jsrspns = JSON.parse(serverResponse);
      if (!jsrspns.success) error_toast(this, JSON.stringify(jsrspns.error));
      else this.file_data = jsrspns.data;
    },
    update() {
      let vm = this;
      if (vm.selected_time && vm.selected_time.value) {
        post_request(vm, "/admin/do-get-priorities", {
          time: vm.selected_time.value,
        })
          .then((response) => {
            let onSelect = (data) => {
              vm.$refs.item_modal.setItem(data);
              vm.modal_visible = true;
            };

            let columns = [
              {
                data: function (row) {
                  return row.bus_business.name;
                },
              },
              {
                data: function (row) {
                  return row.brand_name;
                },
              },
              {
                data: function (row) {
                  return row.priority;
                },
              },
              {
                data: function (row) {
                  return row.image_url;
                },
              },
            ];

            init_datable(vm.$refs.table, response, columns, onSelect);
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
    // upload_data() {
    //   let vm = this;
    //   post_request(vm, "/admin/do-save-priorities", {
    //     data: vm.file_data,
    //     time:
    //       vm.$attrs && vm.$attrs.selected_time
    //         ? vm.$attrs.selected_time.value
    //         : undefined,
    //   })
    //     .then((response) => {
    //       vm.content = response;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       error_toast(vm, err);
    //     });
    // },
    success() {
      this.update();
    },
  },
  mounted() {
    // const fileInput = document.querySelector(
    //   "#file-js-example input[type=file]",
    // );
    // fileInput.onchange = () => {
    //   if (fileInput.files.length > 0) {
    //     const fileName = document.querySelector("#file-js-example .file-name");
    //     fileName.textContent = fileInput.files[0].name;
    //     console.log(fileName);
    //   }
    // };
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
<style scoped>
.tr-green {
  background-color: lightgreen;
}

.tr-red {
  background-color: lightcoral;
}
</style>
