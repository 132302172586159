<template>
  <div class="columns">
    <div class="column">
      <h1 class="title">Usuarios</h1>
      <users />
    </div>
    <div class="column">
      <h1 class="title">Meses</h1>
      <months />
      <h1 class="title">Reporte</h1>
      <report />
    </div>
  </div>
</template>

<script>
// import moment from "moment";
// @ is an alias to /src
// import { api_host, error_toast, post_request, init_datable } from "@/utils";
import Users from "./UsersTimeUsers.vue";
import Months from "./UsersTimeMonths.vue";
import Report from "./Report.vue";

export default {
  name: "UsersTime",
  components: { Users, Months, Report },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
