var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Subir fichero")]),_c('file-pond',{ref:"pond",attrs:{"name":"file","label-idle":"click or drop files here","allow-multiple":true,"allow-revert":false,"server":{
      url: _vm.api_host,
      timeout: 60000,
      process: {
        url: '/admin/do-upload-points-of-sale',
        method: 'POST',
        headers: {
          'x-access-token': _vm.token_session,
        },
        withCredentials: false,
      },
    }},on:{"error":_vm.filepond_error,"processfile":_vm.filepond_process}}),_c('table',{staticClass:"table",staticStyle:{"margin-top":"15px"},attrs:{"width":"100%"}},[_vm._m(0),_c('tbody',_vm._l((_vm.file_data),function(row,index){return _c('tr',{key:index},[_c('th',[_vm._v(_vm._s(row["CUSTOMER_ID"]))]),_c('th',[_vm._v(_vm._s(row["TIPO"]))]),_c('th',[_vm._v(_vm._s(row["NOMBRE_COMERCIAL"]))]),_c('th',[_vm._v(_vm._s(row["GRUPO_NEGOCIO"]))]),_c('th',[_vm._v(_vm._s(row["PUNTO_DE_VENTA"]))]),_c('th',[_vm._v(_vm._s(row["CALLE"]))]),_c('th',[_vm._v(_vm._s(row["COLONIA"]))]),_c('th',[_vm._v(_vm._s(row["DELEGACION"]))]),_c('th',[_vm._v(_vm._s(row.success ? "OK" : row.error))])])}),0)]),_c('h1',{staticClass:"title"},[_vm._v("Mantenimiento")]),_c('table',{ref:"table",staticClass:"ui celled very compact selectable striped table",staticStyle:{"width":"100%"}},[_vm._m(1),_c('tbody')]),_c('item-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.modal_visible),expression:"modal_visible"}],ref:"item_modal",on:{"close":function($event){_vm.modal_visible = false},"success":_vm.success}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("CUSTOMER_ID")]),_c('th',[_vm._v("TIPO")]),_c('th',[_vm._v("NOMBRE_COMERCIAL")]),_c('th',[_vm._v("GRUPO_NEGOCIO")]),_c('th',[_vm._v("PUNTO_DE_VENTA")]),_c('th',[_vm._v("CALLE")]),_c('th',[_vm._v("COLONIA")]),_c('th',[_vm._v("DELEGACION")]),_c('th',[_vm._v("STATUS")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("pos_comercial_name")]),_c('th',[_vm._v("business")]),_c('th',[_vm._v("det_pos")]),_c('th',[_vm._v("city")]),_c('th',[_vm._v("pos_customer_id")])])])}]

export { render, staticRenderFns }