<template>
  <div>
    <h1 class="title">Repositorio</h1>
    <!-- <pre> {{ this.$attrs }}</pre> -->

    <!-- <a class="button is-success" @click="upload_data">
      <span class="icon is-small">
        <i class="fas fa-check"></i>
      </span>
      <span>Upload data</span>
    </a> -->
    <button class="button" @click="update_repo">
      <span class="icon">
        <i class="fab fa-github"></i>
      </span>
      <span>Actualizar repositorio</span>
    </button>
    <h3 class="title">Resultado</h3>
    <pre>{{ result }}</pre>
  </div>
  <!-- </section> -->
</template>

<script>
// @ is an alias to /src
import { post_request } from "@/utils";

export default {
  name: "Repo",
  components: {},
  filters: {},
  props: {},
  data() {
    return { result: {} };
  },
  computed: {},
  methods: {
    update_repo() {
      let vm = this;
      post_request(vm, "/admin/do-update-repo", {})
        .then((r) => {
          vm.result = r;
        })
        .catch((err) => {
          vm.result = err;
        });
    },
  },
  mounted() {},
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
