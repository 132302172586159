<template>
  <div>
    <div class="button is-primary" @click="create">Nuevo mes</div>
    <table
      class="ui celled very compact selectable striped table"
      ref="table"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>Nombre código</th>
          <th>Nombre</th>
          <th>Se muestra</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    <month-modal
      v-show="modal_visible"
      @close="modal_visible = false"
      @success="success"
      ref="month_modal"
    />
  </div>
</template>

<script>
// import moment from "moment";
import { error_toast, post_request, init_datable } from "@/utils";
import MonthModal from "./MonthModal.vue";

export default {
  name: "UsersTimeMonths",
  components: { MonthModal },
  data() {
    return {
      modal_visible: false,
    };
  },
  computed: {},
  methods: {
    create() {
      this.$refs.month_modal.setMonth({});
      this.modal_visible = true;
    },
    load_data() {
      let vm = this;
      post_request(vm, "/admin/do-get-months")
        .then((response) => {
          let onSelect = (data) => {
            vm.$refs.month_modal.setMonth(data);
            vm.modal_visible = true;
          };

          let columns = [
            {
              data: function (row) {
                return row.codename;
              },
            },
            {
              data: function (row) {
                return row.name;
              },
            },
            {
              data: function (row) {
                return row.is_shown ? true : false;
              },
            },
          ];

          init_datable(vm.$refs.table, response, columns, onSelect);
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    success() {
      this.load_data();
    },
  },
  mounted() {
    this.load_data();
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
