<template>
  <div>
    <h1 class="title">Mantenimiento</h1>
    <!-- <pre> {{ this.$attrs }}</pre> -->

    <!-- <a class="button is-success" @click="upload_data">
      <span class="icon is-small">
        <i class="fas fa-check"></i>
      </span>
      <span>Upload data</span>
    </a> -->
    <table
      class="ui celled very compact selectable striped table"
      ref="table"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>{{ "name" }}</th>
          <!-- <th>{{ $t("options") }}</th> -->
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    <item-modal
      v-show="modal_visible"
      @close="modal_visible = false"
      @success="success"
      ref="item_modal"
    />
  </div>
  <!-- </section> -->
</template>

<script>
// @ is an alias to /src
import { error_toast, post_request, init_datable } from "@/utils";
import "datatables.net";
import ItemModal from "./ItemModal.vue";

export default {
  name: "Brands",
  components: {
    ItemModal,
  },
  filters: {},
  props: {},
  data() {
    return {
      modal_visible: false,
    };
  },
  computed: {},
  methods: {
    update() {
      let vm = this;
      post_request(vm, "/admin/do-get-brands", {})
        .then((response) => {
          let onSelect = (data) => {
            vm.$refs.item_modal.setItem(data);
            vm.modal_visible = true;
          };

          let columns = [
            {
              data: function (row) {
                return row.name;
              },
            },
          ];

          init_datable(vm.$refs.table, response, columns, onSelect);
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    success() {
      this.update();
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://cdn.datatables.net/1.10.25/css/dataTables.bulma.min.css");
</style>
<style scoped>
.tr-green {
  background-color: lightgreen;
}

.tr-red {
  background-color: lightcoral;
}
</style>
