<template>
  <div class="is-active modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Punto de venta</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">POS Customer ID</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.pos_customer_id"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Rama</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.branch" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Departamento</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.dto" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Ruta de representante</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.representative_route"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Nombre de representante</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.representative_name"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Ruta promotor</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.promotor_route"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Tipo de negocio</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.business_type" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Nombre comercial</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.comercial_name"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Grupo comercial</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.business_group"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Nombre comercial POS</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.pos_comercial_name"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Calle</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.street" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Colonia</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.colony" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Delegación</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.delegation" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Código ZIP</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.zip_code" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Ciudad</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.city" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Estado</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.estate" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">DET POS</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.det_pos" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Decil</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.decil" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Segmento</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.segment" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Tipo de cliente</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.customer_type" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Brick</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.brick" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <pre> {{ item }} </pre> -->
        <!-- <div class="field">
          <label class="label">Nombre</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="user.name" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Ruta</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="user.route" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Departamento</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="user.department" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Ruta Rep.</label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="text"
              v-model="user.representative_route"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div> -->
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="save">Guardar</button>
        <button class="button" @click="$emit('close')">Cancelar</button>
      </footer>
    </div>
    <!-- <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button> -->
  </div>
</template>

<script>
import { error_toast, post_request } from "@/utils";

export default {
  name: "PointOfSaleModal",
  props: {},
  data() {
    return {
      item: {},
    };
  },
  methods: {
    setItem(item) {
      this.item = item;
    },
    save() {
      let vm = this;
      post_request(vm, "/admin/do-save-point-of-sale", { item: vm.item })
        .then(() => {
          vm.$emit("success");
          vm.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
  },
  created() {},
  watch: {},
};
</script>
