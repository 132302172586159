<template>
  <div>
    <div class="tabs">
      <ul style="max-width: 300px">
        <li
          v-for="(brand, index) in brands"
          :key="index"
          v-bind:class="{
            'is-active': current_tab == brand || (!current_tab && index == 0),
          }"
        >
          <a @click="change_tab(brand)">{{ brand.name }}</a>
        </li>
      </ul>
    </div>
    <div
      v-for="(brand, index) in brands"
      :key="index"
      class="tab-content"
      v-show="current_tab == brand || (!current_tab && index == 0)"
    >
      <div class="columns">
        <div class="column">
          <h3 class="subtitle">
            Plaza primaria {{ brand.name }} (Autoservicios)
          </h3>

          <file-pond
            name="file"
            ref="pond"
            label-idle="click or drop files here"
            v-bind:allow-multiple="true"
            v-bind:allow-revert="false"
            v-bind:server="{
              url: api_host,
              timeout: 7000,
              process: {
                url: '/admin/do-upload-primary-place',
                method: 'POST',
                headers: {
                  'x-access-token': token_session,
                  brand_id: brand.brand_id,
                  type: 'autoservices',
                },
                withCredentials: false,
              },
            }"
            v-on:error="filepond_error"
            v-on:processfile="filepond_process"
          />

          <div class="field">
            <label class="label">Texto</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Inserte el contenido"
                v-model="brands[index].setup.autoservice_text"
              />
            </div>
          </div>
          <div class="columns">
            <div
              class="column"
              v-for="(fl, idx) in brand.setup.autoservices"
              :key="idx"
            >
              <div class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img
                      :src="api_host + '/' + fl.relative_path"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <a
                      class="button is-link"
                      @click="remove(idx, brand.setup.autoservices)"
                      >Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <h3 class="subtitle">Plaza primaria {{ brand.name }} (Farmacias)</h3>
          <file-pond
            name="file"
            ref="pond"
            label-idle="click or drop files here"
            v-bind:allow-multiple="true"
            v-bind:allow-revert="false"
            v-bind:server="{
              url: api_host,
              timeout: 7000,
              process: {
                url: '/admin/do-upload-primary-place',
                method: 'POST',
                headers: {
                  'x-access-token': token_session,
                  brand_id: brand.brand_id,
                  type: 'drugstores',
                },
                withCredentials: false,
              },
            }"
            v-on:error="filepond_error"
            v-on:processfile="filepond_process"
          />

          <div class="field">
            <label class="label">Texto</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Inserte el contenido"
                v-model="brands[index].setup.drugstore_text"
              />
            </div>
          </div>
          <div class="columns">
            <div
              class="column"
              v-for="(fl, idx) in brand.setup.drugstores"
              :key="idx"
            >
              <div class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img
                      :src="api_host + '/' + fl.relative_path"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <a
                      class="button is-link"
                      @click="remove(idx, brand.setup.drugstores)"
                      >Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button is-success" @click="save(brand)">
        <span class="icon is-small">
          <i class="fas fa-check"></i>
        </span>
        <span>Guardar</span>
      </div>
    </div>

    <!-- <a class="button is-success" @click="save">
      <span class="icon is-small">
        <i class="fas fa-check"></i>
      </span>
      <span>Guardar</span>
    </a> -->
  </div>
</template>

<script>
import moment from "moment";
// @ is an alias to /src
import { api_host, error_toast, post_request } from "@/utils";
import vueFilePond from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: "PrimaryPlace",
  components: {
    FilePond,
  },
  filters: {
    format_date(date) {
      if (!date) return "";
      else return moment(date).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      api_host: api_host,
      // files: [],
      current_tab: undefined,
      brands: [],
    };
  },
  computed: {
    token_session() {
      let token = this.$session.get("token");
      return token;
    },
    time() {
      let vm = this;
      return vm.$attrs && vm.$attrs.selected_time
        ? vm.$attrs.selected_time.value
        : "";
    },
  },
  methods: {
    filepond_error(a, b) {
      console.log("error", a, b);
    },
    filepond_process(event, inst) {
      let vm = this;
      let serverResponse = inst.serverId;
      let jsrspns = JSON.parse(serverResponse);
      if (!jsrspns.success) error_toast(this, JSON.stringify(jsrspns.error));
      else {
        let file = jsrspns.data.file;
        let origin = jsrspns.data.origin || {};
        let trgtBrand = vm.brands.find((b) => b.brand_id == origin.brand_id);
        if (trgtBrand) {
          if (!trgtBrand.setup[origin.origin])
            trgtBrand.setup[origin.origin] = [file];
          else trgtBrand.setup[origin.origin].push(file);
        }
      }
    },
    save(brand) {
      let vm = this;
      post_request(vm, "/admin/do-save-primary-place", {
        brand: brand,
        time:
          vm.$attrs && vm.$attrs.selected_time
            ? vm.$attrs.selected_time.value
            : undefined,
      })
        .then(() => {
          error_toast(vm, "Configuración almacenada");
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    disenable(place, status, index) {
      let vm = this;
      post_request(vm, "/admin/do-disenable-primary-place", {
        place: place.place_id,
        status: status,
      })
        .then((place) => {
          error_toast(vm, "Actualizado");
          vm.files.splice(index, 1, place);
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    remove(index, list) {
      list.splice(index, 1);
      // let vm = this;
      // post_request(vm, "/admin/do-remove-primary-place", {
      //   place: place.place_id,
      // })
      //   .then(() => {
      //     vm.files.splice(index, 1);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     error_toast(vm, err);
      //   });
    },
    update() {
      let vm = this;
      let time =
        vm.$attrs && vm.$attrs.selected_time
          ? vm.$attrs.selected_time.value
          : undefined;
      if (time) {
        post_request(vm, "/admin/do-get-primary-place", {
          time:
            vm.$attrs && vm.$attrs.selected_time
              ? vm.$attrs.selected_time.value
              : undefined,
        })
          .then((response) => {
            vm.brands = response;
            // vm.brands = response.map((r) => {
            //   r.text = r.text || "kiay " + r.name;
            //   return r;
            // });
            // console.log(vm.brands);
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
    change_tab(tab) {
      this.current_tab = tab;
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
