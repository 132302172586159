<template>
  <div class="is-active modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Material POP</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Nombre corto</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="item.short_name" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Nombre completo</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="item.full_name" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Ruta de imágen</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="item.image_url" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <!-- <div class="field">
          <label class="label">Nombre</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="user.name" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Ruta</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="user.route" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Departamento</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" v-model="user.department" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Ruta Rep.</label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="text"
              v-model="user.representative_route"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div> -->
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="save">Guardar</button>
        <button class="button" @click="$emit('close')">Cancelar</button>
      </footer>
    </div>
    <!-- <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button> -->
  </div>
</template>

<script>
import { error_toast, post_request } from "@/utils";

export default {
  name: "PopMaterialModal",
  props: {},
  data() {
    return {
      item: {},
    };
  },
  methods: {
    setItem(item) {
      this.item = item;
    },
    save() {
      let vm = this;
      post_request(vm, "/admin/do-save-pop-material", { item: vm.item })
        .then(() => {
          vm.$emit("success");
          vm.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
  },
  created() {},
  watch: {},
};
</script>
