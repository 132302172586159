<template>
  <div class="container">
    <section class="hero">
      <div class="hero-body">
        <p class="title">Administración</p>
        <p class="subtitle">
          <time-dropdown
            @selected="selected"
            ref="time_dropdown"
            :show_all="true"
          />
        </p>
      </div>
    </section>
    <div class="columns">
      <div class="column is-2">
        <div class="tabs is-centered">
          <ul>
            <li v-bind:class="{ 'is-active': current_tab == 'product' }">
              <a @click="change_tab('product')">Productos</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'priorities' }">
              <a @click="change_tab('priorities')">Prioridades</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'promotions' }">
              <a @click="change_tab('promotions')">Promociones</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'pharmaceutical' }">
              <a @click="change_tab('pharmaceutical')">Farmaceutica</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'primary_place' }">
              <a @click="change_tab('primary_place')">Plaza primaria</a>
            </li>
            <li
              v-bind:class="{ 'is-active': current_tab == 'secondary_place' }"
            >
              <a @click="change_tab('secondary_place')">Plaza secundaria</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'pos' }">
              <a @click="change_tab('pos')">Puntos de venta</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'pop_materials' }">
              <a @click="change_tab('pop_materials')">Materiales POP</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'users_time' }">
              <a @click="change_tab('users_time')">Usuarios y Meses</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'brands' }">
              <a @click="change_tab('brands')">Marcas</a>
            </li>
            <li v-bind:class="{ 'is-active': current_tab == 'update_repo' }">
              <a @click="change_tab('update_repo')">Repositorio</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="column is-10">
        <products
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'product'"
          ref="products_component"
        />
        <priorities
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'priorities'"
          ref="priorities_component"
        />
        <promotions
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'promotions'"
          ref="promotions_component"
        />
        <pharmaceutical
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'pharmaceutical'"
          ref="pharmaceutical_component"
        />
        <primary-place
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'primary_place'"
          ref="primary_place_component"
        />
        <secondary-place
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'secondary_place'"
          ref="secondary_place_component"
        />
        <points-of-sale
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'pos'"
          ref="pos_component"
        />
        <pop-materials
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'pop_materials'"
          ref="pop_material_component"
        />
        <users-time
          :selected_time="selected_time"
          class="tab-content"
          v-show="current_tab == 'users_time'"
          ref="users_time_component"
        />
        <brands
          class="tab-content"
          v-show="current_tab == 'brands'"
          ref="brands_component"
        />
        <repo
          class="tab-content"
          v-show="current_tab == 'update_repo'"
          ref="repo_component"
        />
      </div>
    </div>
    <div id="tabs-with-content">
      <div></div>
    </div>
  </div>
</template>

<script>
import Priorities from "./admin/Priorities/Priorities.vue";
import Promotions from "./admin/Promotions/Promotions.vue";
import PopMaterials from "./admin/PopMaterials/PopMaterials.vue";
import PointsOfSale from "./admin/PointsOfSale/PointsOfSale.vue";
import PrimaryPlace from "./admin/PrimaryPlace.vue";
import SecondaryPlace from "./admin/SecondaryPlace/SecondaryPlace.vue";
// import moment from "moment";
// @ is an alias to /src
// import { api_host, error_toast } from "@/utils";
// import vueFilePond from "vue-filepond";
// import "filepond/dist/filepond.min.css";
import Products from "./admin/Products/Products.vue";
import Pharmaceutical from "./admin/Pharmaceutical.vue";
import UsersTime from "./admin/UsersTime/UsersTime.vue";
import Brands from "./admin/Brands/Brands.vue";
import Repo from "./admin/Repo.vue";

import TimeDropdown from "@/components/TimeDropdown";
import moment from "moment";

// const FilePond = vueFilePond();

export default {
  name: "Admin",
  components: {
    // FilePond,
    Products,
    Priorities,
    Promotions,
    Pharmaceutical,
    PrimaryPlace,
    SecondaryPlace,
    PointsOfSale,
    PopMaterials,
    TimeDropdown,
    UsersTime,
    Brands,
    Repo,
  },
  filters: {},
  data() {
    return {
      current_tab: "product",
      selected_time: {},
    };
  },
  computed: {},
  methods: {
    change_tab(tab) {
      this.current_tab = tab;
    },
    selected(item) {
      this.selected_time = item;
      setTimeout(() => {
        this.$refs.products_component.update();
        this.$refs.priorities_component.update();
        this.$refs.promotions_component.update();
        this.$refs.pharmaceutical_component.update();
        this.$refs.primary_place_component.update();
        this.$refs.secondary_place_component.update();
        // this.$refs.pos_component.update();
        this.$refs.pop_material_component.update();
      }, 200);
    },
  },
  mounted() {
    let currentTime = moment().format("MMYYYY");
    setTimeout(() => {
      this.$refs.time_dropdown.setSelected(currentTime);
    }, 200);
  },
};
</script>

<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://cdn.datatables.net/1.10.25/css/jquery.dataTables.min.css");
@import url("https://cdn.datatables.net/1.10.25/css/dataTables.bulma.min.css");
</style>

<style scoped>
.tabs ul {
  -webkit-flex-direction: column;
  flex-direction: column;
}
</style>
