<template>
  <div>
    <h1 class="title">Subir fichero</h1>
    <!-- <section class="tab-content" v-show="current_tab == 'promotions'"> -->
    <file-pond
      name="file"
      ref="pond"
      label-idle="click or drop files here"
      v-bind:allow-multiple="true"
      v-bind:allow-revert="false"
      v-bind:server="{
        url: api_host,
        timeout: 60000,
        process: {
          url: '/admin/do-upload-secondary-place?time=' + time,
          method: 'POST',
          headers: {
            'x-access-token': token_session,
          },
          withCredentials: false,
        },
      }"
      v-on:error="filepond_error"
      v-on:processfile="filepond_process"
    />

    <table class="table" width="100%" style="margin-top: 15px">
      <thead>
        <tr>
          <th>det pos</th>
          <th>customer id</th>
          <th>nombre comercial</th>
          <th>renta</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in file_data" :key="index">
          <th>{{ row["det_pos"] }}</th>
          <th>{{ row["pos_customer_id"] }}</th>
          <th>{{ row["comercial_name"] }}</th>
          <th>{{ row["rent"] }}</th>
        </tr>
      </tbody>
    </table>

    <h1 class="title">Mantenimiento</h1>

    <!-- <a class="button is-success" @click="upload_data">
      <span class="icon is-small">
        <i class="fas fa-check"></i>
      </span>
      <span>Upload data</span>
    </a> -->
    <table
      class="ui celled very compact selectable striped table"
      ref="table"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>det pos</th>
          <th>customer id</th>
          <th>business</th>
          <th>nombre comercial</th>
          <th>renta</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>

    <div class="container">
      <div>
        <h1 class="title">Rentas</h1>
      </div>
      <ul class="block-list">
        <li
          class="has-icon"
          v-for="(rent, index) in calculated_rents"
          :key="index"
        >
          <span class="icon">
            <i class="fas fa-plane"></i>
          </span>
          <span>{{ rent.name }}</span>
          <div class="field is-grouped">
            <div class="field">
              <label class="label">Imágen</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Ruta de la imágen"
                  v-model="calculated_rents[index].image"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Icono</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Ruta de la imágen"
                  v-model="calculated_rents[index].icon"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <a class="button is-primary" @click="save_rents">
        Guardar información de imágenes de rentas
      </a>
    </div>
    <item-modal
      v-show="modal_visible"
      @close="modal_visible = false"
      @success="success"
      ref="item_modal"
    />
  </div>
</template>

<script>
import moment from "moment";
// @ is an alias to /src
import { api_host, error_toast, post_request, init_datable } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
// import { v4 } from "uuid";
import ItemModal from "./ItemModal.vue";

const FilePond = vueFilePond();

export default {
  name: "SecondaryPlace",
  components: {
    FilePond,
    ItemModal,
  },
  filters: {
    format_date(date) {
      if (!date) return "";
      else return moment(date).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      api_host: api_host,
      file_data: [],
      current_tab: undefined,
      rents: [],
      calculated_rents: [],
      modal_visible: false,
    };
  },
  computed: {
    token_session() {
      let token = this.$session.get("token");
      return token;
    },
    time() {
      let vm = this;
      return vm.$attrs && vm.$attrs.selected_time
        ? vm.$attrs.selected_time.value
        : "";
    },
  },
  methods: {
    filepond_error(a, b) {
      console.log("error", a, b);
    },
    filepond_process(event, inst) {
      let serverResponse = inst.serverId;
      let jsrspns = JSON.parse(serverResponse);
      if (!jsrspns.success) error_toast(this, JSON.stringify(jsrspns.error));
      else this.file_data = jsrspns.data;
    },
    update() {
      let vm = this;
      post_request(vm, "/admin/do-get-secondary-place", {
        time:
          vm.$attrs && vm.$attrs.selected_time
            ? vm.$attrs.selected_time.value
            : undefined,
      })
        .then((response) => {
          vm.rents = response;

          post_request(vm, "/admin/do-get-rents", {
            time:
              vm.$attrs && vm.$attrs.selected_time
                ? vm.$attrs.selected_time.value
                : undefined,
          })
            .then((rents) => {
              if (!rents || (rents && !rents.length)) vm.calculate_rents();
              else {
                vm.calculated_rents = rents;
              }
            })
            .catch((err) => {
              console.log(err);
              error_toast(vm, err);
            });

          let onSelect = (data) => {
            vm.$refs.item_modal.setItem(data);
            vm.modal_visible = true;
          };

          let columns = [
            {
              data: function (row) {
                return row.det_pos;
              },
            },
            {
              data: function (row) {
                return row.pos_customer_id;
              },
            },
            {
              data: function (row) {
                return row.bus_business.name;
              },
            },
            {
              data: function (row) {
                return row.comercial_name;
              },
            },
            {
              data: function (row) {
                return row.rent;
              },
            },
          ];
          init_datable(vm.$refs.table, response, columns, onSelect);
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    calculate_rents() {
      let vm = this;
      if (vm.rents && vm.rents.length) {
        let rr = [...new Set(vm.rents.map((x) => x.rent))];
        rr = rr
          .map((r) => {
            return {
              name: r,
              // uuid: v4(),
              image: "",
              icon: "",
            };
          })
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            return 0;
          });
        vm.calculated_rents = rr;
      } else vm.calculated_rents = [];
    },
    save_rents() {
      let vm = this;
      // let rnts = vm.calculated_rents.map((r) => {
      //   r.time =
      //     vm.$attrs && vm.$attrs.selected_time
      //       ? vm.$attrs.selected_time.value
      //       : undefined;
      //   return r;
      // });
      post_request(vm, "/admin/do-save-rents", {
        rents: vm.calculated_rents,
        time:
          vm.$attrs && vm.$attrs.selected_time
            ? vm.$attrs.selected_time.value
            : undefined,
      })
        .then(() => {
          error_toast("Información almacenada");
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    success() {
      this.update();
    },
  },
  mounted() {
    // this.update();
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
<style scoped>
.tr-green {
  background-color: lightgreen;
}

.tr-red {
  background-color: lightcoral;
}
</style>
