<template>
  <div>
    <div class="button is-primary" @click="create">Nuevo usuario</div>
    <table
      class="ui celled very compact selectable striped table"
      ref="table"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>Usuario</th>
          <th>Nombre</th>
          <th>Ruta</th>
          <th>Departamento</th>
          <th>Ruta Rep.</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    <user-modal
      v-show="modal_visible"
      @close="modal_visible = false"
      @success="success"
      ref="user_modal"
    />
  </div>
</template>

<script>
// import moment from "moment";
import { error_toast, post_request, init_datable } from "@/utils";
import UserModal from "./UserModal.vue";

export default {
  name: "UsersTimeUsers",
  components: { UserModal },
  data() {
    return {
      modal_visible: false,
    };
  },
  computed: {},
  methods: {
    create() {
      this.$refs.user_modal.setUser({});
      this.modal_visible = true;
    },
    load_data() {
      let vm = this;
      post_request(vm, "/admin/do-get-users")
        .then((response) => {
          let onSelect = (data) => {
            vm.$refs.user_modal.setUser(data);
            vm.modal_visible = true;
          };

          let columns = [
            {
              data: function (row) {
                return row.username;
              },
            },
            {
              data: function (row) {
                return row.name;
              },
            },
            {
              data: function (row) {
                return row.route;
              },
            },
            {
              data: function (row) {
                return row.department;
              },
            },
            {
              data: function (row) {
                return row.representative_route;
              },
            },
          ];

          init_datable(vm.$refs.table, response, columns, onSelect);
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    success() {
      this.load_data();
    },
  },
  mounted() {
    this.load_data();
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
