var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs"},[_c('ul',{staticStyle:{"max-width":"300px"}},_vm._l((_vm.brands),function(brand,index){return _c('li',{key:index,class:{
          'is-active': _vm.current_tab == brand || (!_vm.current_tab && index == 0),
        }},[_c('a',{on:{"click":function($event){return _vm.change_tab(brand)}}},[_vm._v(_vm._s(brand.name))])])}),0)]),_vm._l((_vm.brands),function(brand,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current_tab == brand || (!_vm.current_tab && index == 0)),expression:"current_tab == brand || (!current_tab && index == 0)"}],key:index,staticClass:"tab-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h3',{staticClass:"subtitle"},[_vm._v(" Plaza primaria "+_vm._s(brand.name)+" (Autoservicios) ")]),_c('file-pond',{ref:"pond",refInFor:true,attrs:{"name":"file","label-idle":"click or drop files here","allow-multiple":true,"allow-revert":false,"server":{
            url: _vm.api_host,
            timeout: 7000,
            process: {
              url: '/admin/do-upload-primary-place',
              method: 'POST',
              headers: {
                'x-access-token': _vm.token_session,
                brand_id: brand.brand_id,
                type: 'autoservices',
              },
              withCredentials: false,
            },
          }},on:{"error":_vm.filepond_error,"processfile":_vm.filepond_process}}),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Texto")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.brands[index].setup.autoservice_text),expression:"brands[index].setup.autoservice_text"}],staticClass:"input",attrs:{"type":"text","placeholder":"Inserte el contenido"},domProps:{"value":(_vm.brands[index].setup.autoservice_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.brands[index].setup, "autoservice_text", $event.target.value)}}})])]),_c('div',{staticClass:"columns"},_vm._l((brand.setup.autoservices),function(fl,idx){return _c('div',{key:idx,staticClass:"column"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"image is-4by3"},[_c('img',{attrs:{"src":_vm.api_host + '/' + fl.relative_path,"alt":"Placeholder image"}})])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('a',{staticClass:"button is-link",on:{"click":function($event){return _vm.remove(idx, brand.setup.autoservices)}}},[_vm._v("Eliminar")])])])])])}),0)],1),_c('div',{staticClass:"column"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Plaza primaria "+_vm._s(brand.name)+" (Farmacias)")]),_c('file-pond',{ref:"pond",refInFor:true,attrs:{"name":"file","label-idle":"click or drop files here","allow-multiple":true,"allow-revert":false,"server":{
            url: _vm.api_host,
            timeout: 7000,
            process: {
              url: '/admin/do-upload-primary-place',
              method: 'POST',
              headers: {
                'x-access-token': _vm.token_session,
                brand_id: brand.brand_id,
                type: 'drugstores',
              },
              withCredentials: false,
            },
          }},on:{"error":_vm.filepond_error,"processfile":_vm.filepond_process}}),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Texto")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.brands[index].setup.drugstore_text),expression:"brands[index].setup.drugstore_text"}],staticClass:"input",attrs:{"type":"text","placeholder":"Inserte el contenido"},domProps:{"value":(_vm.brands[index].setup.drugstore_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.brands[index].setup, "drugstore_text", $event.target.value)}}})])]),_c('div',{staticClass:"columns"},_vm._l((brand.setup.drugstores),function(fl,idx){return _c('div',{key:idx,staticClass:"column"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"image is-4by3"},[_c('img',{attrs:{"src":_vm.api_host + '/' + fl.relative_path,"alt":"Placeholder image"}})])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('a',{staticClass:"button is-link",on:{"click":function($event){return _vm.remove(idx, brand.setup.drugstores)}}},[_vm._v("Eliminar")])])])])])}),0)],1)]),_c('div',{staticClass:"button is-success",on:{"click":function($event){return _vm.save(brand)}}},[_vm._m(0,true),_c('span',[_vm._v("Guardar")])])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-check"})])}]

export { render, staticRenderFns }