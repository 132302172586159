<template>
  <div class="is-active modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Plaza secundaria</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">POS Customer ID</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.pos_customer_id"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Nombre comercial</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="item.comercial_name"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">DET POS</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.det_pos" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Renta</label>
              <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" v-model="item.rent" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <pre> {{ item }} </pre> -->
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="save">Guardar</button>
        <button class="button" @click="$emit('close')">Cancelar</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { error_toast, post_request } from "@/utils";

export default {
  name: "SecondaryPlaceModal",
  props: {},
  data() {
    return {
      item: {},
    };
  },
  methods: {
    setItem(item) {
      this.item = item;
    },
    save() {
      let vm = this;
      post_request(vm, "/admin/do-save-secondary-place", { item: vm.item })
        .then(() => {
          vm.$emit("success");
          vm.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
  },
  created() {},
  watch: {},
};
</script>
