var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Subir fichero")]),_c('file-pond',{ref:"pond",attrs:{"name":"file","label-idle":"click or drop files here","allow-multiple":true,"allow-revert":false,"server":{
      url: _vm.api_host,
      timeout: 7000,
      process: {
        url: '/admin/do-upload-products?time=' + _vm.time,
        method: 'POST',
        headers: {
          'x-access-token': _vm.token_session,
        },
        withCredentials: false,
      },
    }},on:{"error":_vm.filepond_error,"processfile":_vm.filepond_process}}),_c('table',{staticClass:"table",staticStyle:{"margin-top":"15px"},attrs:{"width":"100%"}},[_vm._m(0),_c('tbody',_vm._l((_vm.file_data),function(row,index){return _c('tr',{key:index,class:{
          'tr-green': row.success,
          'tr-red': !row.success,
        }},[_c('th',[_vm._v(_vm._s(row["FAMILIA"]))]),_c('th',[_vm._v(_vm._s(row["DESCRIPCION_SKU"]))]),_c('th',[_vm._v(_vm._s(row["CODIGO EAN"]))]),_c('th',[_vm._v(_vm._s(_vm._f("format_currency")(row["PRECIO_MINIMO"])))]),_c('th',[_vm._v(_vm._s(_vm._f("format_currency")(row["PRECIO_MAXIMO"])))]),_c('th',[_vm._v(_vm._s(row.success ? "OK" : row.error))])])}),0)]),_c('h1',{staticClass:"title"},[_vm._v("Mantenimiento")]),_c('table',{ref:"table",staticClass:"ui celled very compact selectable striped table",staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s("ean_code"))]),_c('th',[_vm._v(_vm._s("maximum_price"))]),_c('th',[_vm._v(_vm._s("minimum_price"))]),_c('th',[_vm._v(_vm._s("name"))]),_c('th',[_vm._v(_vm._s("product"))])])]),_c('tbody')]),_c('item-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.modal_visible),expression:"modal_visible"}],ref:"item_modal",on:{"close":function($event){_vm.modal_visible = false},"success":_vm.success}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Familia")]),_c('th',[_vm._v("Descripción SKU")]),_c('th',[_vm._v("Código EAN")]),_c('th',[_vm._v("Precio mínimo")]),_c('th',[_vm._v("Precio máximo")]),_c('th',[_vm._v("Resultado")])])])}]

export { render, staticRenderFns }