<template>
  <div>
    <time-dropdown
      @selected="time_selected"
      ref="time_dropdown"
      :show_all="false"
    />
    <div class="button is-primary" @click="download">Descargar</div>
  </div>
</template>

<script>
// import moment from "moment";
import { error_toast, post_request } from "@/utils";
import TimeDropdown from "@/components/TimeDropdown.vue";

export default {
  name: "Report",
  components: { TimeDropdown },
  data() {
    return {
      selected_time: "",
    };
  },
  computed: {},
  methods: {
    download() {
      let vm = this;
      post_request(vm, "/admin/do-get-report", { time: vm.selected_time })
        .then((response) => {
          let csv = response.reduce((prev, curr) => {
            return `${prev}"${curr.username}","${curr.name}","${curr.route}","${curr.department}","${curr.representative_route}","${curr.usr_logs}"\n`;
          }, `"Usuario","Nombre","Ruta","Departamento","Ruta Rep.","Logs"\n`);
          console.log("csv", csv);
          vm.download_content(csv, `logs_report_${vm.selected_time.value}.csv`);
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
    },
    time_selected(time) {
      this.selected_time = time;
    },
    download_content(data, name) {
      var blob = new Blob([data], {
        type: "application/json;charset=utf-8",
      });
      var url = URL.createObjectURL(blob);
      var elem = document.createElement("a");
      elem.href = url;
      elem.download = name;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    },
  },
  mounted() {},
};
</script>

<style src="@/assets/css/style.css"></style>
<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
